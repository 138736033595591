@import "../../../styles/variables";
#freelancers-app {
    .credits-screen {
        h2 {
            color: black;
            text-shadow: none;
        }
        a {
            color: black;
        }
        p {
            @include n(margin, 1.5);
        }
    }

    .variants-screen {
        h2 {
            color: black;
            text-shadow: none;
        }
        a {
            color: black;
        }
        p, ul, li {
            @include n(margin, 1.5);
            align-self: flex-start;
            text-align: left;
            font-size: 80%;
        }
    }
}