@font-face {
    font-family: CheddarGothic;
    src: url('./fonts/CheddarGothic-Serif.otf');
}

@font-face {
    font-family: Poppins;
    src: url('./fonts/Poppins-Regular.otf');
}

@font-face {
    font-family: Poppins;
    font-weight: 1200;
    src: url('./fonts/Poppins-Bold.otf');
}

@font-face {
    font-family: Poppins;
    font-style: italic;
    src: url('./fonts/Poppins-Italic.otf');
}

@font-face {
    font-family: Poppins;
    font-weight: light;
    src: url('./fonts/Poppins-Light.otf');
}

@font-face {
    font-family: Poppins;
    font-weight: bold;
    src: url('./fonts/Poppins-Bold.otf');
}