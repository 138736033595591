@import "../../../styles/variables";

.options-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    h3 {
        text-align: center;
    }
    &.text-size-5 h3 {
        font-size: 120%;
        line-height: 110%;
    }
    &.text-size-6 h3 {
        font-size: 160%;
        line-height: 110%;
    }
    select {
        text-transform: uppercase;
        font-size: 110%;
        min-width: 30%;
        text-align: center;
        font-weight: bold;
        @include n(padding, 1);
        @include n(border-radius, 2);
    }
}