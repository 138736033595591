@import '../../../styles/variables';

.main-menu {
    .freelancers-logo {
        width: 100%;
        @include n(margin, 5 0 7 0);
    }

    .update-message {
        background-color: rgba(0,0,0,.5);
        color: white;
        @include n(padding, .5 7);
        @include n(border-radius, 3);
        @include n(width, 60);
        @include n(margin, 2 0);
        a {
            color: white;
            text-decoration: underline;
        }
    }
    
    footer {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include n(margin, 10 0 0 0);
        .studio-logo-container {
            width: 70%;
            height: auto;
            z-index: 2;
        }
        .footnote {
            color: white;
            text-align: center;
            font-size: 70%;
            z-index: 2;
            font-weight: bold;
        }
        .footer-gradient {
            position: fixed;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to bottom, transparent, black);
            @include n(height, 30);
            z-index: 1;
            pointer-events: none;
        }
    }
}

