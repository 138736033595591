@import "variables";
@import "fonts";

@mixin stroke() {
    text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 1.75517px 0.958851px 0px, rgb(0, 0, 0) 1.0806px 1.68294px 0px, rgb(0, 0, 0) 0.141474px 1.99499px 0px, rgb(0, 0, 0) -0.832294px 1.81859px 0px, rgb(0, 0, 0) -1.60229px 1.19694px 0px, rgb(0, 0, 0) -1.97998px 0.28224px 0px, rgb(0, 0, 0) -1.87291px -0.701566px 0px, rgb(0, 0, 0) -1.30729px -1.5136px 0px, rgb(0, 0, 0) -0.421592px -1.95506px 0px, rgb(0, 0, 0) 0.567324px -1.91785px 0px, rgb(0, 0, 0) 1.41734px -1.41108px 0px, rgb(0, 0, 0) 1.92034px -0.558831px 0px;
}

.tutorial-spacer {
    height: 40vh;
    @include superwide {
        height: 80vh;
    }
    @include superduperwide {
        height: 0;
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;
}
button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.cheddar {
    font-family: CheddarGothic;
    @include stroke;
    color: white;
    font-weight: normal;
}

.poppins {
    font-family: Poppins;
}

hr {
    $w: 90;
    width: $w+0vw;
    height: $w/18.4+0vw;
    @include wide {
        $w: 368;
        width: $w+0px;
        height: $w/18.4+0px;
    }
    border: 0;
    background-color: transparent;
    padding: 0;
    @include n(margin, 2);
    &.thin {
        @include n(margin, .1);
    }
    border: none;
    background-image: url('../images/flinterface/Divider.png');
    background-size: 100% 100%;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
}

h3 {
    font-weight: bold;
    font-family: Poppins;
    padding: 0;
    font-size: 90%;
    @include n(margin, 3 0);
}

body {
    user-select: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffd6a3;
    @include wide {
        background-color: #926bba;
    }
    @include n(font-size, 4);
    line-height: 110%;
    font-family: Poppins;
}

img {
    max-width: 100%;
}

#freelancers-app {
    .fw-icon {
        @include n(height, 3.5);
        width: auto;
        @include n(margin, .2 0 0);
    }
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .dynamic-background {
        display: none;
        &.main-menu {
            display: block;
        }
        @include wide {
            display: block;
        }
        position: fixed;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: cover;
        background-position: right bottom;
        transition: .3s;
        overflow: hidden;
        &.main-menu {
            background-image: url('../images/flinterface/home-bg.jpg');
        }
        &.skeleton {
            background-image: url('../images/backgrounds/Background-Skeleton.jpg');
        }
        &.gilded-district {
            background-image: url('../images/backgrounds/Background-Gilded_District.jpg');
        }
        &.faerie-ridge {
            background-image: url('../images/backgrounds/Background-Faerie_Ridge.jpg');
        }
        &.the-great-library {
            background-image: url('../images/backgrounds/Background-The_Great_Library.jpg');
        }
        &.ambush {
            background-image: url('../images/backgrounds/Background-Ambush.jpg');
        }
        &.attack {
            background-image: url('../images/backgrounds/Background-Attack.jpg');
        }
        &.corrupt-ferry {
            background-image: url('../images/backgrounds/Background-Corrupt_Ferry.jpg');
        }
        &.lonely-cottage {
            background-image: url('../images/backgrounds/Background-Lonley_Cottage.jpg');
        }
        &.treasure-golem {
            background-image: url('../images/backgrounds/Background-Treasure_Golem.jpg');
        }
        &.highway {
            background-image: url('../images/backgrounds/Background-Highway.jpg');
        }
        &.marketplace {
            background-image: url('../images/backgrounds/Background-Marketplace.jpg');
        }
        &.river-village {
            background-image: url('../images/backgrounds/Background-River_Village.jpg');
        }
        &.opera-house {
            background-image: url('../images/backgrounds/Background-Opera_House.jpg');
        }
        &.map-d {
            background-image: url('../images/backgrounds/Backgrounds-MapD.jpg');
        }
        &.dark-corridors {
            background-image: url('../images/backgrounds/Background-Dark_Corridors.jpg');
        }
        &.slums-hub {
            background-image: url('../images/backgrounds/Background-Slums_Hub.jpg');
        }
        &.wastes {
            background-image: url('../images/backgrounds/Background-Wastes.jpg');
        }
        &.entertainment-district {
            background-image: url('../images/backgrounds/Background-Entertainment_District.jpg');
        }
        &.map-a {
            background-image: url('../images/backgrounds/Backgrounds-MapA.jpg');
        }
        &.dragon {
            background-image: url('../images/backgrounds/Background-Dragon.jpg');
        }
        &.mushroom-village {
            background-image: url('../images/backgrounds/Background-Mushroom_Village.jpg');
        }
        &.driftlands {
            background-image: url('../images/backgrounds/Background-Driftlands.jpg');
        }
        &.trap {
            background-image: url('../images/backgrounds/Background-Trap.jpg');
        }
        &.paz-the-displayer {
            background-image: url('../images/backgrounds/Background-Paz_the_Displayer.jpg');
        }
        &.cemetery {
            background-image: url('../images/backgrounds/Background-Cemetery.jpg');
        }
        &.the-lich {
            background-image: url('../images/backgrounds/Background-The_Lich.jpg');
        }
        &.map-e {
            background-image: url('../images/backgrounds/Backgrounds-MapE.jpg');
        }
        &.map-c {
            background-image: url('../images/backgrounds/Backgrounds-MapC.jpg');
        }
        &.map-b {
            background-image: url('../images/backgrounds/Backgrounds-MapB.jpg');
        }
        &.bg-left {
            position: fixed;
            z-index: -100;
            right: 50%;
            margin-right: 235px;
        }
        &.bg-right {
            position: fixed;
            z-index: -100;
            left: 50%;
            margin-left: 235px;

        }
        .bg-left {
            position: fixed;
            z-index: -100;
            right: 50%;
            margin-right: 235px;
        }
        .bg-right {
            position: fixed;
            z-index: -100;
            left: 50%;
            margin-left: 235px;

        }
    }

    .popup-bg {
        position: fixed;
        z-index: 499;
        background-color: rgba(0,0,0,.5);
        width: 100%;
        height: 100%;
        cursor: pointer;
        &-follower {
            z-index: 497;
        }
    }

    .popup-follower {
        position: fixed;
        z-index: 498;
        width: 100vw;
        height: 156.5vw;
        top: 0;
        @media only screen and (max-aspect-ratio: 594/930) and (min-width: 500px) {
            width: 500px;
            height: 783px;
        }
        @media only screen and (min-aspect-ratio: 594/930) {
            height: 100vh;
            width: 63.9vh;
            @media only screen and (min-height: 783px) {
                height: 783px;
                width: 500px;
            }
        }
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-image: url('../images/follower-art/Follower_Pop_Up-1.png');
        &-inner {
            width: 100%;
            height: 100%;
            padding: 9.5% 6.5% 6% 5.7%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        h3 {
            color: black;
            margin: 0 0 0 2%;
            font-size: 130%;
            line-height: 110%;
            text-shadow: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            height: 10%;
            overflow: hidden;
            width: 56%;
            align-self: flex-start;
            overflow-x: hidden;
            @media only screen and (min-aspect-ratio: 594/450) and (max-height: 782px) {
                font-size: 80%;
            }
        }
        .audio-player-container {
            .audio-player {
                .ui {
                    @media only screen and (min-aspect-ratio: 594/450) and (max-height: 782px) {
                        @include n(width, 6);
                        @include n(height, 6/.95);
                        @include n(margin, 3 2 1.5);
                    }
                }
            }
            .audio-player-error {
                color: red;
                font-size: 80%;
            }
        }
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 90%;
            width: 100%;
            padding: 2.5%;
            overflow-x: hidden;
            box-sizing: border-box;
            .follower-back-container {
                cursor: pointer;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    @include n(width, 10);
                    @include n(margin-right, 2);
                    height: auto;
                }
            }
            .follower-entry {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                overflow-y: scroll;
                height: 93%;
                max-width: 95%;
                .entry-content {
                    &.content-text {
                        font-size: 90%;
                        &.text-size-5 {
                            font-size: 150%;
                            line-height: 110%;
                        }
                        &.text-size-6 {
                            font-size: 200%;
                            line-height: 110%;
                        }
                        @include wide {
                            font-size: 75%;
                            &.text-size-5 {
                                font-size: 110%;
                                line-height: 110%;
                            }
                            &.text-size-6 {
                                font-size: 150%;
                                line-height: 110%;
                            }
                        }
                    }
                }
                .wide-button-entry {
                    $w: 75;
                    @include n(width, $w);
                    @include n(height, $w/5.08);
                    flex-shrink: 0;
                    max-width: 100%;
                    @media only screen and (min-aspect-ratio: 594/450) and (max-height: 782px) {
                        @include n(width, 40);
                        @include n(height, 40/5.08);
                        font-size: 50%;
                    }
                }
                .follower-first {
                    min-height: 25vw;
                    @include wide {
                        min-height: 160px;
                    }
                    @media only screen and (min-aspect-ratio: 594/450) and (max-height: 782px) {
                        min-height: 100px;
                    }
                    
                    .entry-content {
                        &.content-text {
                            text-align: center;
                        }
                    }
                    width: 100%;
                    padding-right: 50%;
                    flex-shrink: 0;
        
                    padding-bottom: 4%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                }
                
            }
        }
        &.follower-num {
            &-1 {
                background-image: url('../images/follower-art/Follower_Pop_Up-1.png');
            }
            &-2 {
                background-image: url('../images/follower-art/Follower_Pop_Up-2.png');
            }
            &-3 {
                background-image: url('../images/follower-art/Follower_Pop_Up-3.png');
                h3 {
                    height: 14%;
                }
            }
            &-4 {
                background-image: url('../images/follower-art/Follower_Pop_Up-4.png');
            }
            &-5 {
                background-image: url('../images/follower-art/Follower_Pop_Up-5.png');
            }
            &-6 {
                background-image: url('../images/follower-art/Follower_Pop_Up-6.png');
            }
            &-7 {
                background-image: url('../images/follower-art/Follower_Pop_Up-7.png');
            }
            &-8 {
                background-image: url('../images/follower-art/Follower_Pop_Up-8.png');
            }
            &-9 {
                background-image: url('../images/follower-art/Follower_Pop_Up-9.png');
            }
            &-10 {
                background-image: url('../images/follower-art/Follower_Pop_Up-10.png');
            }
            &-11 {
                background-image: url('../images/follower-art/Follower_Pop_Up-11.png');
            }
            &-12 {
                background-image: url('../images/follower-art/Follower_Pop_Up-12.png');
            }
            &-13 {
                background-image: url('../images/follower-art/Follower_Pop_Up-13.png');
            }
            &-14 {
                background-image: url('../images/follower-art/Follower_Pop_Up-14.png');
            }
            &-15 {
                background-image: url('../images/follower-art/Follower_Pop_Up-15.png');
            }
            &-16 {
                background-image: url('../images/follower-art/Follower_Pop_Up-16.png');
            }
            &-17 {
                background-image: url('../images/follower-art/Follower_Pop_Up-17.png');
            }
            &-18 {
                background-image: url('../images/follower-art/Follower_Pop_Up-18.png');
            }
            &-19 {
                background-image: url('../images/follower-art/Follower_Pop_Up-19.png');
            }
            &-20 {
                background-image: url('../images/follower-art/Follower_Pop_Up-20.png');
            }
            &-21 {
                background-image: url('../images/follower-art/Follower_Pop_Up-21.png');
            }
            &-22 {
                background-image: url('../images/follower-art/Follower_Pop_Up-22.png');
            }
            &-23 {
                background-image: url('../images/follower-art/Follower_Pop_Up-23.png');
            }
            &-24 {
                background-image: url('../images/follower-art/Follower_Pop_Up-24.png');
            }
            &-25 {
                background-image: url('../images/follower-art/Follower_Pop_Up-25.png');
                h3 {
                    height: 14%;
                }
            }
            &-26 {
                h3 {
                    height: 14%;
                }
                background-image: url('../images/follower-art/Follower_Pop_Up-26.png');
            }
        }
        background-size: 100% 100%;
    }
    .popup-follower-close {
        position: absolute;
        right: 2%;
        top: 4%;
        cursor: pointer;
        height: auto;
        width: 10%;
    }

    .popup {
        position: fixed;
        z-index: 1100;
        background-color: #fcd5a1;
        top: 10%;
        height: 80%;
        @include n(width, 93);
        border: solid #591f2f;
        @include n(border-width, 1);
        @include n(border-radius, 6);
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: 0;
        h3 {
            @include n(border-radius, 5 5 0 0);
            width: 100%;
            text-align: center;
            font-size: 130%;
            font-weight: bold;
            background-color: #d6a67a;
            @include n(height, 14);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin: 0;
            flex-shrink: 0;
        }
        hr.popup-hr {
            @include n(width, 91.1);
            @include n(height, 4.6);
            margin: 0;
            position: absolute;
            @include n(top, 11.2);
            flex-shrink: 0;
        }
        .popup-content {
            overflow-y: scroll;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            @include n(padding, 4 6 2);
            font-size: 80%;
            font-weight: bold;
            line-height: 170%;
            max-height: auto;
            
            span {
                width: 100%;
                img {
                    align-self: center;
                }
                &.glossary-img-container {
                    width: 100%;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
            }
            .related-term {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .popup-close {
            position: absolute;
            @include n(right, -3.5);
            @include n(top, -3.5);
            cursor: pointer;
            height: auto;
            @include n(width, 11);
        }
    }

    .content {
        position: absolute;
        z-index: 10;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 4vw;
        overflow-x: hidden;
        overflow-y: hidden;
        @include wide {
            width: 450px;
            padding: 15px 0;
        }
        &.runner-bg {
            background-color: #ffd6a3;
            min-height: 100vh;            
            padding: 0 4vw;
            @include wide {
                padding: 0 28px;
            }
        }
        &.with-header {
            @include n(padding-top, 10);
        }
        p {
            text-align: center;
            @include n(margin, 4 0);
            font-size: 80%;
            &.narrow {
                max-width: 70%;
            }
        }
        h2.upper {
            text-shadow: none;
            color: black;
            width: 100%;
            @include n(height, 10);
            background-image: url('../images/flinterface/Subtitle_Bar.png');
            background-size: 100% 100%;
            position: absolute;
            text-align: center;
            margin: 0;
            padding: 0;
            @include n(top, 10);
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 130%;
        }
        .with-top {
            @include n(margin-top, 10);
        }
        .with-top-top {
            @include n(margin-top, 20);
        }
    }
    .left-brick {
        pointer-events: none;
        display: none;
        position: absolute;
        left: -22px;
        top: 0;
        height: 100%;
        width: 40px;
        background-image: url('../images/flinterface/Left_Brick.png');
        background-size: 100% auto;
        z-index: 20;
        @include wide {
            display: block;
        }
    }
    .right-brick {
        pointer-events: none;
        display: none;
        position: absolute;
        right: -22px;
        top: 0;
        height: 100%;
        width: 40px;
        background-image: url('../images/flinterface/Right_Brick.png');
        background-size: 100% auto;
        z-index: 20;
        @include wide {
            display: block;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .entry-form {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            p {
                font-weight: bold;
                font-size: 70%;
                @include n(margin, 1 0);
            }
            .form-line {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                input.entry-go {
                    text-align: center;
                    @include n(height, 10);
                    @include n(border-radius, 1);
                    @include n(width, 23);
                    outline: none;
                    border: none;
                    @include n(font-size, 6);
                    @include n(margin-right, 2);
                }
            }
            p.error {
                color: red;
                font-weight: bold;
            }
        }
    }
}

.wide-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 100% 100%;
    @include n(margin, 2 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    box-sizing: border-box;
    &[disabled] {
        opacity: .6;
        cursor: default;
    }
    &-title {
        $w: 88;
        @include n(width, $w);
        @include n(height, $w/5.2);
        background-image: url('../images/flinterface/Blue_Button.png');
        color: white;
        font-weight: normal;
        @include stroke;
        font-size: 220%;
        @include n(padding, 0 0 .4 0);
    }
    &-subtitle {
        $w: 83;
        @include n(width, $w);
        @include n(height, $w/5.08);
        background-image: url('../images/flinterface/Orange_Button.png');
        color: white;
        font-weight: normal;
        @include stroke;
        font-size: 210%;
        @include n(padding, 0 0 .5 0);
    }
    &-entry {
        $w: 83;
        @include n(width, $w);
        @include n(height, $w/5.08);
        background-image: url('../images/flinterface/Orange_Button.png');
        color: white;
        font-weight: normal;
        font-size: 100%;
        line-height: 110%;
        font-style: italic;
        @include n(padding, 0 0 .5 0);
        @include wide {
            font-size: 75%;
        }
        &.tutorial-button {
            font-style: normal;
        }
        span {
            max-width: 85%;
        }
        img.fw-icon {
            filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(139deg) brightness(98%) contrast(90%);
        }
    }
    &-entry-purple {
        $w: 83;
        @include n(width, $w);
        @include n(height, $w/5.08);
        background-image: url('../images/flinterface/Option_Button.png');
        color: white;
        font-weight: normal;
        font-size: 100%;
        line-height: 110%;
        font-style: italic;
        @include n(padding, 0 0 .5 0);
        @include wide {
            font-size: 75%;
        }
        &.tutorial-button {
            font-style: normal;
        }
        span {
            max-width: 85%;
        }
        img.fw-icon {
            filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(139deg) brightness(98%) contrast(90%);
        }
    }
    &-entry-blue {
        $w: 83;
        @include n(width, $w);
        @include n(height, $w/5.08);
        background-image: url('../images/flinterface/Blue_Location_Button.png');
        color: black;
        font-weight: normal;
        text-shadow: none;
        font-size: 160%;
        line-height: 110%;
        font-style: italic;
        @include n(padding, 0 0 .5 0);
        &.tutorial-button {
            font-style: normal;
        }
        span {
            max-width: 85%;
        }
        img.fw-icon {
            filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(139deg) brightness(98%) contrast(90%);
        }
    }
    &-entry-green {
        $w: 83;
        @include n(width, $w);
        @include n(height, $w/5.08);
        background-image: url('../images/flinterface/Green_Location_Button.png');
        color: black;
        font-weight: normal;
        text-shadow: none;
        font-size: 160%;
        line-height: 110%;
        font-style: italic;
        @include n(padding, 0 0 .5 0);
        &.tutorial-button {
            font-style: normal;
        }
        span {
            max-width: 85%;
        }
        img.fw-icon {
            filter: invert(100%) sepia(0%) saturate(7483%) hue-rotate(139deg) brightness(98%) contrast(90%);
        }
    }
    &-small {
        $w: 27;
        @include n(width, $w);
        @include n(height, $w/2.26);
        background-image: url('../images/flinterface/Go_Button.png');
        color: white;
        font-weight: normal;
        @include stroke;
        font-size: 160%;
        @include n(padding, 0 0 .5 0);
    }
    &-small-extended {
        $w: 36;
        @include n(width, $w);
        @include n(height, $w/3);
        background-image: url('../images/flinterface/Go_Button.png');
        color: white;
        font-weight: normal;
        @include stroke;
        font-size: 160%;
        @include n(padding, 0 0 .5 0);
    }
    &-job {
        $w: 18;
        @include n(width, $w);
        @include n(height, $w/1.91);
        background-image: url('../images/flinterface/Job_Button-Unselected.png');
        color: black;
        font-weight: normal;
        font-size: 80%;
        @include n(margin, 1);
        text-shadow: none;
        line-height: 100%;
    }
    &-job-selected {
        $w: 18;
        @include n(width, $w);
        @include n(height, $w/1.91);
        background-image: url('../images/flinterface/Job_Button-Selected.png');
        color: black;
        font-weight: normal;
        font-size: 80%;
        @include n(margin, 1);
        text-shadow: none;
        line-height: 100%;
    }
}

.wooden-header {
    position: absolute;
    top: 0;
    z-index: 15;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url('../images/flinterface/Top_Wood.png');
    background-size: auto 100%;
    @include n(height, 10.4);
    @include n(padding, 0 6 .4);
    box-sizing: border-box;
    &-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        @include n(width, 19);
        .settings {
            @include n(margin-left, 2);
        }
    }
    h1 {
        @include n(font-size, 5);
        
    }
}

.back {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    padding: 0;
    img {
        width: auto;
        @include n(height, 6.5);
    }
}

.settings {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    padding: 0;
    img {
        width: auto;
        @include n(height, 7);
    }
}

.text-hr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include n(margin, 2);
    &-line {
        width: 33vw;
        @include wide {
            width: 125px;
        }
    }
    &-text {
        font-size: 60%;
        font-weight: bold;
        @include n(margin, 0 2);
    }
    
}

.learn-to-play-arrow {
    &.noshow {
        opacity: 0;
    }
    opacity: 1;
    transition: opacity .5s;
    position: absolute;
    top:0;
    left:0;
    z-index: 500;
    pointer-events: none;
    color: black;
    font-size: 16px;
    text-shadow: none;
    width: 171px;
    height: 125px;
    background-image: url('../images/flinterface/arrow-left.png');
    background-size: 100% 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 45px 35px 45px 43px;
}

.pulse {
    animation: pulse 2s infinite;
    transform: scale(.8);
}
@keyframes pulse {
    0% {
        transform: scale(.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}