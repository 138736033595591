@import "../../../styles/variables";

.tutorial-blocker {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
}

.tutorial {
    background-image: url('../../../images/flinterface/Tutorial_Box.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    position: fixed;
    bottom: 0;
    height: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 110%;
    padding: 2vw 8% 0;
    @include wide {
        width: 500px;
        padding: 12px 34px 0;
    }
    @include superwide {
        right: -15px;
    }
    &-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 13vw;
        box-sizing: border-box;
        padding: 1vw 2% 0;
        z-index: 1001;
        @include wide {
            height: 58px;
            padding: 5px 10px 0;
        }
        &-min, &-close {
            cursor: pointer;
            width: 10%;
            img {
                width: 100%;
                height: auto;
            }
        }
        &-title {
            text-shadow: none;
            font-size: 190%;
        }
    }
    &-entry {
        @include n(margin-top, .3);
        width: 100%;
        padding: 3vw;
        max-height: 40vh;
        &.can-block {
            max-height: 76vh;
        }
        overflow-y: scroll;
        box-sizing: border-box;
        z-index: 1001;
        @include wide {
            padding: 10px;
        }
        @include superwide {
            max-height: 85vh;
        }
        .entry-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .content-image {
                max-width: 80%;
                height: auto;
            }
            
            .content-text {
                width: 100%;
                text-align: center;
                p { text-align: center; width: 100%; }
                span { text-align: center; width: 100%; }
                margin-bottom: 2vw;
                @include wide {
                    margin-bottom: 8px;
                }
                li {
                    text-align: left;
                }
                font-weight: medium;
                font-size: 90%;
                line-height: 130%;
                &.text-size-5 {
                    font-size: 130%;
                    line-height: 110%;
                }
                &.text-size-6 {
                    font-size: 160%;
                    line-height: 110%;
                }
            }
        }
    }
   
}