@import "../../../styles/variables";

.species-selection {
    .species-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .select-species {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .one-species {
                position: relative;
                @include n(width, 27);
                @include n(height, 27);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                img {
                    @include n(width, 38);
                    max-width: none;
                    pointer-events: none;
                }
                span {
                    position: absolute;
                    bottom: 21%;
                    left: auto;
                    font-size: 80%;
                    color: black;
                    text-shadow: none;
                }
            }
            
        }
        .select-jobs {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}