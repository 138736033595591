@import "../../../styles/variables";

#freelancers-app {
    .content.play-menu {
        .scenario-option-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            @include n(margin, 0 0 8);
            .scenario-option {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                @include n(margin, 0 4);
                @include n(width, 16);
                &.option-species {
                    @include n(width, 20);
                }
                span {
                    color: #6c3625;
                    text-shadow: none;
                    font-size: 150%;
                    line-height: 110%;
                }
                
            }
        }
        .scenario-option {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            @include n(margin, 0 4);
            @include n(width, 16);
            &.toppy {
                @include n(width, 12);
            }
            &.option-species {
                @include n(width, 20);
            }
            &.option-glossary {
                @include n(width, 22);
            }
            span {
                color: #6c3625;
                text-shadow: none;
                font-size: 150%;
                line-height: 110%;
                @include n(width, 16);
            }
            
        }
        .follower-overlay {
            position: fixed;
            z-index: 100;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,.5);
        }
        .follower-display {
            position: fixed;
            z-index: 501;
            $w: 90;
            @include n(top, 5);
            @include n(width, $w);
            @include n(height, $w/.629);
            background-image: url('../../../images/flinterface/Follower Panel.png');
            background-size: 100% 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            box-sizing: border-box;
            @include n(padding, 2 2 4 2);
            .follower-button {
                position: relative;
                padding: 0;
                .follower-token {
                    @include n(width, 18);
                    @include n(margin, 0 1);
                    height: auto;
                }
                .follower-number {
                    color: black;
                    text-shadow: none;
                    font-size: 125%;
                    position: absolute;
                    top: 7%;
                    text-align: center;
                    left: 6%;
                    width: 27%;
                }
            }
            .follower-close {
                cursor: pointer;
                position: absolute;
                @include n(top, -2.5);
                @include n(right, -2.5);
                @include n(width, 10);
            }
        }
        .quickfill {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &-followers {
                @include n(width, 15);
                cursor: pointer;
                @include n(margin-right, 3);
            }
            &-entries {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                @include n(width, 60);
                .quickfill-entry {
                    padding: 0;
                    color: black;
                    text-shadow: none;
                    font-size: 120%;
                    @include n(width, 8);
                    text-align: center;
                    @include n(margin, 1 2);
                }
            }
        }
        h2.page-selector {
            cursor: pointer;
            @include n(padding-right, 8);
            .dropdown {
                @include n(width, 4);
                @include n(margin-right, 4);
                height: auto;
            }
        }
        .stop-here {
            font-weight: bold;
            @include n(margin-top, 1);
            font-size: 65%;
            &-save {
                color: #cb242c;
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .play-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        .page-select {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            color: black;
            text-shadow: none;

            .one-page {
                @include n(margin, 1 0);
                @include n(width, 20);
                text-align: center;
                cursor: pointer;
            }
        }
        .book-header {
            @include n(margin-top, 2);
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .book-image {
                @include n(width, 52);
            }
            .map-image {
                @include n(width, 70);
            }
        }
        .map-icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .map-icon-button {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                @include n(width, 13);
                @include n(margin, 1 .1);
                img {
                    width: 100%;
                    height: auto;
                }
                span {
                    color: black;
                    text-shadow: none;
                    font-size: 70%;
                }
            }
        }
    }
}