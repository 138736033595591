@import '../../../styles/variables';

.time-left {
    position: relative;
    @include n(margin, 6 0 3);
    .hourglass {
        @include n(width, 25);
        height: auto;
    }
    .seconds {
        position: absolute;
        top: 35.3%;
        left: 18%;
        width: 63%;
        height: 28%;
        text-align: center;
        font-size: 300%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}
.timer-message {
    font-weight: bold;
    text-align: center;
    font-size: 80%;
    color: red;
    line-height: 100%;
    @include n(height, 6);
}