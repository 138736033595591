@import "../../../../styles/variables";

#freelancers-app .entry-display {
    .quickfill-followers {
        @include n(width, 15);
        cursor: pointer;
    }

    .follower-overlay {
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
    }
    .follower-display {
        position: fixed;
        z-index: 501;
        $w: 90;
        @include n(top, 5);
        @include n(width, $w);
        @include n(height, $w/.629);
        background-image: url('../../../../images/flinterface/Follower Panel.png');
        background-size: 100% 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        @include n(padding, 2 2 4 2);
        .follower-button {
            position: relative;
            padding: 0;
            .follower-token {
                @include n(width, 18);
                @include n(margin, 0 1);
                height: auto;
            }
            .follower-number {
                color: black;
                text-shadow: none;
                font-size: 125%;
                position: absolute;
                top: 7%;
                text-align: center;
                left: 6%;
                width: 27%;
            }
        }
        .follower-close {
            cursor: pointer;
            position: absolute;
            @include n(top, -2.5);
            @include n(right, -2.5);
            @include n(width, 10);
        }
    }

    h2.guide {
        position: absolute;
        @include n(height, 10);
        @include n(top, 10);
        margin: 0;
        width: 100%;
        padding: 0;
        text-align: center;
        text-shadow: none;
        color: black;
        font-size: 130%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #fdf8df;
    }
    h2.upper.cheddar.setup {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include n(top, 20);
        button {
            @include n(width, 15);
            height: auto;
            @include n(margin, 0 3);
            &[disabled] {
                opacity: 0;
            }
        }
    }
    .entry-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include n(padding, 1 0 0);
        .entry-content {
            @include n(margin, 2.5 0);
            width: 100%;
            
            &.content-text {
                font-size: 110%;
                &.text-size-5 {
                    font-size: 150%;
                    line-height: 110%;
                }
                &.text-size-6 {
                    font-size: 200%;
                    line-height: 110%;
                }
                @include wide {
                    font-size: 75%;
                    &.text-size-5 {
                        font-size: 110%;
                        line-height: 110%;
                    }
                    &.text-size-6 {
                        font-size: 150%;
                        line-height: 110%;
                    }
                }
            }
        }        
    }
    
}

.tut-treasure {
    &-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        @include n(margin, 2 0);
    }
    &-item {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @include n(margin, 0 0 4);
        cursor: pointer;
        img {
            width: 70%;
            height: auto;
        }
        span {
            font-weight: bold;
            text-align: center;
            @include n(margin, 1 0 0);
        }
    }
}

.tut-risk {
    &-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        @include n(margin, 1 0);
    }
    &-token {
        width: 23%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @include n(margin, 0 0 1);
        cursor: pointer;
        img {
            width: 100%;
            height: auto;
        }
    }
}

.entry-history-display {
    .entry-content {
        @include n(margin, 2.5 0);
        h2 {
            font-size: 110%;
            font-weight: bold;
            @include n(margin, 1 0);
        }
        &.content-text {
            font-size: 100%;
            &.text-size-5 {
                font-size: 150%;
                line-height: 110%;
            }
            &.text-size-6 {
                font-size: 200%;
                line-height: 110%;
            }
            @include wide {
                font-size: 75%;
                line-height: 110%;
                &.text-size-5 {
                    font-size: 110%;
                    line-height: 110%;
                }
                &.text-size-6 {
                    font-size: 150%;
                    line-height: 110%;
                }
            }
        }
    }
    .fw-icon {
        @include n(height, 3.5);
        width: auto;
        @include n(margin, .2 0 0);
    }
}

.entry-arrow {
    &.hidden {
        opacity: 0,
    }
    transition: opacity .5s;
    position: absolute;
    top:0;
    left:0;
    z-index: 500;
    pointer-events: none;
    color: black;
    font-size: 0px;
    text-shadow: none;
    width: 171px;
    height: 125px;
    background-image: url('../../../../images/flinterface/arrow-left.png');
    background-size: 100% 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 45px 35px 45px 43px;
    &.flip {
        background-image: url('../../../../images/flinterface/arrow-right.png');
    }
}