@import "../../styles/variables";

.audio-player-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .audio-player {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ui {
            $w: 11;
            @include n(width, $w);
            @include n(height, $w/.95);
            background-size: 100% 100%;
            opacity: .5;
            @include n(margin, 3 2 1.5);
            &.rw {
                background-image: url("../../images/flinterface/Rewind10.png");
            }
            &.pp {

            }
            &.ff {
                background-image: url("../../images/flinterface/Forward10.png");
            }
            &.active {
                cursor: pointer;
                opacity: 1;
            }
            &.play {
                background-image: url("../../images/flinterface/Play_Button.png");
            }
            &.pause {
                background-image: url("../../images/flinterface/Pause_Button.png");
            }
        }
    }
    .audio-player-error {
        color: red;
        font-size: 80%;
    }
}

.follower-first {
    .audio-player-container {
        max-width: 100%;
        .audio-player {
            .ui {
                 @include n(margin, 3 1 1.5);
            }
        }
        .audio-player-error {
        }
    }
}