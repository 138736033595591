@import '../../../styles/variables';

.scenario-selection {
    .scenario-select-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
    }
    .one-scenario {
        position: relative;
        cursor: pointer;
        &.unreleased {
            cursor: default;
            opacity: .4;
        }
        h3 {
            position: absolute;
            top: 12%;
            margin: 0;
            height: 22%;
            left: 10%;
            width: 60%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 105%;
            cursor: pointer;
        }
        .scenario-difficulty {
            position: absolute;
            top: 32.5%;
            font-size: 85%;
            margin: 0;
            left: 11%;
            width: 60%;
            height: 19%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .difficulty-axe {
                height: 80%;
                width: auto;
                @include n(margin-left, .5);
            }
        }
    }
    .release-date {
        font-weight: bold;
        font-size: 75%;
        @include n(margin, -1 0 1 0);
    }
}