@mixin wide { //full width
  @media only screen and (min-width: 494px) {
    @content;
  }
}

@mixin superwide { //wide enough for side tutorial
  @media only screen and (min-width: 900px) {
    @content;
  }
}

@mixin superduperwide { //wide enough for side tutorial
  @media only screen and (min-width: 1300px) {
    @content;
  }
}

@function u($num, $wide: false) {
  @if $wide { //fixed full size
    @return $num*4.8+0px;
  } @else { //size scales with screen width
    @return $num+0vw;
  }
}

//spits out scaled values for a property based on screen width
//ex: n(width, 5) => width: 5vw; @media only screen and (min-width: 494px) { width: 24px; }
//ex: n(margin, 5 0 0 0) => margin: 5vw 0 0 0; @media only screen and (min-width: 494px) { margin: 24px 0 0 0; }
@mixin n($property, $nums) {
  $values: ();
  $widevalues: ();
  @each $num in $nums {
    $values: append($values, u($num));
    $widevalues: append($widevalues, u($num, true));
  }
  #{$property}: $values;
  @include wide {
    #{$property}: $widevalues;
  }
}