@import "../../../../styles/variables";

.glossary-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    @include n(padding, 3);
    
    input.glossary-search {
        @include n(width, 76);
        font-weight: bold;
        @include n(height, 8);
        text-align: center;
        @include n(margin-bottom, 3);
        @include n(padding, 1);
        font-size: 120%;
        border: 1px solid black;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .glossary-results {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .result {
            background-color: #d7a67b;
            border: 2px solid #ab7a58;
            @include n(width, 76);
            @include n(height, 8);
            @include n(margin, 1.5 0);
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 110%;
            font-weight:bold;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}