@import "../../../styles/variables";

.delete-quest {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.quest-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    span {
        font-size: 70%;
    }
    .one-quest {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        @include n(margin, 5 0 0);
        font-weight: bold;
        &-date, &-code {
            font-size: 90%;
        }
        &-load {
            font-size: 100%;
        }
        &-input {
            border-radius: 6px;
            border: 1px solid black;
            font-weight: bold;
            @include n(width, 40);
            @include n(height, 9);
            @include n(margin, 2 0 0);
            text-align: center;
            font-size: 95%;
        }
        &-error {
            font-size: 90%;
            font-weight: bold;
            color: red;
        }
        &-loading {
            font-size: 100%;
            @include n(margin, 6 0 );
        }
        &-title {
            font-size: 120%;
            line-height: 110%;
            @include n(margin, 2 0);
        }
        &-delete {
            position: absolute;
            @include n(top, -2);
            right: 0;
            img {
                @include n(width, 4);
                height: auto;
            }
        }
    }
}