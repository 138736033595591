@import "../../../styles/variables";

.scenario-dev {
    display: none;
    @include wide {
        display: flex;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        overflow: scroll;
        width: 25%;
        z-index: 100000;
        background-color: white;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .debug-entry {
            width: 100%;
            box-sizing: border-box;
            padding: 2px;
            border-bottom: 1px solid black;
            user-select: all;
            cursor: pointer;
            &.active {
                background-color: blue;
                color: white;
            }
        }
    }
}